<template>
  <div class="bigbox">
  </div>
</template>

<script>
export default {
  name: 'ConTrol',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bigbox {
  display: flex;
  // width: 1px;
  height: 960px;
  border: 1px greenyellow transparent;
}
</style>
