<template>
  <div class="logo">
    <!-- <img alt="Vue logo" src="../assets/images/background/logon.png"> -->
    <HelloWorld msg="" @passfuntion="massage"></HelloWorld>
    <div class="content">
      <UserInfo msg="" />
      <MiddleScent
        :table6="table6"
        :table7="table7"
        :table8="table8"
        :table9="table9"
        :table10="table10"
        :table11="table11"
        :table12="table12"
        :table13="table13"
      ></MiddleScent>
      <ReportLicensing
        :table1="table1"
        :table2="table2"
        :table3="table3"
        :table4="table4"
        :table5="table5"
      ></ReportLicensing>
      <ConTrol />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import UserInfo from "./children/UserInfo.vue";
import MiddleScent from "./children/MiddleScent.vue";
import ReportLicensing from "./children/ReportLicensing.vue";
import ConTrol from "./children/ConTrol.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    UserInfo,
    MiddleScent,
    ReportLicensing,
    ConTrol,
  },
  data() {
    return {
      age: [],
      table1: {},
      table2: {},
      table3: [],
      table4: [],
      table5: [],
      table6: [],
      table7: [],
      table8: [],
      table9: [],
      table10: [],
      table11: [],
      table12: [],
      table13: [],
    };
  },
  methods: {
    massage(val) {
      this.age = val;
      this.table1 = this.age[1];
      this.table2 = this.age[2];
      this.table3 = this.age[3];
      this.table4 = this.age[4];
      this.table5 = this.age[5];
      this.table6 = this.age[6];
      this.table7 = this.age[7];
      this.table8 = this.age[8];
      this.table9 = this.age[9];
      this.table10 = this.age[10];
      this.table11 = this.age[11];
      this.table12 = this.age[12];
      this.table13 = this.age[13];
    },
  },
};
</script>
<style lang="less" scoped>
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 960px;
  // padding: 100px 0;
  color: #fff;
  text-align: center;
  background: url(../assets/images/background/logon.jpg) no-repeat center center;
  background-size: 100% 100%;
}
.content {
  display: flex;
}
</style>
