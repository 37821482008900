import axios from 'axios'
// import Vue from 'vue'
import { BASE_URL, TIMEOUT } from '../config/nidex'

var http = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
});

// 添加请求拦截器
http.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers['Authorization'] = localStorage.getItem('zhcp_token');
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// // 添加响应拦截器
// http.interceptors.response.use(function (response) {
//   // 对响应数据做点什么
//   let data = response.data;
//   if (!data.code) {
//     return
//   }
//   // if (data.code != 200) {
//   //   Vue.prototype.$alert(data.msg, '警告', {
//   //     confirmButtonText: '确定',
//   //     type: 'error',
//   //   });
//   //   return Promise.reject();

//   // }
//   return data;
// }, function (error) {
//   // 对响应错误做点什么
//   Vue.prototype.$alert("系统繁忙，请稍后重试", '警告', {
//     confirmButtonText: '确定',
//     type: 'error'
//   });
//   return Promise.reject(error);
// });


//接口防抖
let ary = [];//所有的请求接口 请求完成移除
function myhttp(...arg) {
  let first = ary[0];
  if (typeof first === 'object') {
    first = first.url
  }
  if (ary.includes(first)) {
    //证明上一次还没请求完成
    return
  } else {
    ary.push(first)
  }
  http(...arg).finally(() => {
    let n = ary.indexOf(first);
    ary.splice(n, 1)
  })
}

myhttp.get = function (...arg) {
  let first = ary[0];
  if (ary.includes(first)) {
    return Promise.reject('重复请求')
  } else {
    ary.push(first)
  }
  let p = http.get(...arg);
  p.finally(() => {
    let n = ary.indexOf(first);
    ary.splice(n, 1)
  })
  return p
}

myhttp.post = function (...arg) {
  let first = ary[0];
  if (ary.includes(first)) {
    return Promise.reject('重复请求')
  } else {
    ary.push(first)
  }
  let p = http.post(...arg);
  p.finally(() => {
    let n = ary.indexOf(first);
    ary.splice(n, 1)
  })
  return p
}

export default http
