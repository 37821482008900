import myhttp from './port'

// 用户总体信息
export function getList(params) {
  return myhttp({
    url: 'big/getUserCount',
    method: 'get',
    params
  })
}

// 线索总体信息
export function getListClue(params) {
  return myhttp({
    url: 'big/getAllClueCount',
    method: 'get',
    params
  })
}

// 线索总体信息
export function getListClueA(params) {
  return myhttp({
    url: 'big/getAllClueList',
    method: 'get',
    params
  })
}

// 线索总体信息
export function getListClueB(params) {
  return myhttp({
    url: 'big/getAllClueType',
    method: 'get',
    params
  })
}
// 线索总体信息曲线图
export function getLisA(params) {
  return myhttp({
    url: 'big/getClueA',
    method: 'get',
    params
  })
}
// 线索总体信息曲线图
export function getLisB(params) {
  return myhttp({
    url: 'big/getClueB',
    method: 'get',
    params
  })
}
// 线索总体信息曲线图
export function getLisC(params) {
  return myhttp({
    url: 'big/getClueC',
    method: 'get',
    params
  })
}
// 线索总体信息曲线图
export function getLisD(params) {
  return myhttp({
    url: 'big/getClueD',
    method: 'get',
    params
  })
}

// 中1 柱状图  数据
export function getHistogramA(params) {
  return myhttp({
    url: 'big/getHistogramA',
    method: 'get',
    params
  })
}
// 中2 柱状图  数据
export function getHistogramB(params) {
  return myhttp({
    url: 'big/getHistogramB',
    method: 'get',
    params
  })
}
// 中3 柱状图  数据
export function getHistogramC(params) {
  return myhttp({
    url: 'big/getHistogramC',
    method: 'get',
    params
  })
}
// 右3 柱状图  数据
export function getHistogramD(params) {
  return myhttp({
    url: 'big/getHistogramD',
    method: 'get',
    params
  })
}

// 公益诉讼检察 选择
export function getListypeA(params) {
  return myhttp({
    url: 'big/getClueTypeA',
    method: 'get',
    params
  })
}
// 行政公益诉讼
export function getListypeB(params) {
  return myhttp({
    url: 'big/getClueTypeB',
    method: 'get',
    params
  })
}
// 行政公益诉讼-国有财产保护
export function getListypeC(params) {
  return myhttp({
    url: 'big/getClueTypeC',
    method: 'get',
    params
  })
}
// 行政许可
export function getListypeD(params) {
  return myhttp({
    url: 'big/getClueTypeD',
    method: 'get',
    params
  })
}
// 行政报备总体信息  数据
// export function getExecutive(params) {
//   return myhttp({
//     url: 'big/getExecutive',
//     method: 'get',
//     params
//   })
// }
// 行政报备总体信息  数据
export function getExecutive(params) {
  return myhttp({
    url: 'big/getWangOne',
    method: 'get',
    params
  })
}

// 饼图数据  数据
export function getExWangA(params) {
  return myhttp({
    url: 'big/getWangTwo',
    method: 'get',
    params
  })
}


// 饼图
export function getExS(params) {
  return myhttp({
    url: 'big/getEx',
    method: 'get',
    params
  })
}

// 柱状图 
export function getExWangT(params) {
  return myhttp({
    url: 'big/getWangThree',
    method: 'get',
    params
  })
}

// 时间搜索
export function timeSearch(data) {
  return myhttp({
    url: '/big/getWangAllTable',
    method: 'post',
    data
  })
}