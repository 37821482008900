<template>
  <div class="bigbox">
    <div class="left"> {{dates}}  {{xinqi}}</div>
    <div class="right">
      <div class="date" >
      <!-- <button @click="Aoass">chuanzhi</button> -->
    <el-date-picker
      v-model="value1"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions"
      placement="bottom-start"
      value-format="yyyy-MM-dd"
      @change="handleMonth">
    </el-date-picker>
      <!-- :default-time="['00:00:00', '23:59:59']" -->

      </div>
      
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {timeSearch} from "@/api/user.js";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      dates: +new Date(),
      xinqi:'',
      value1:[],
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;//-8.64e6是选择今天的
        }
      },
      chidren:[]
    }
  },
  created () {
    this.start()
    // this.handleMonth()
  },
  mounted() {
    this.handleMonth()
  },
  methods: {
    start () {
      this.dates = moment(this.dates).format('YYYY年MM月DD日')
      this.xinqi =  moment(new Date()).format('dddd')
    },
    // Aoass() {
    //   this.$emit("passfuntion",this.chidren)
    // },
    handleMonth(val) {
      if(!val){
        this.startTime = ''
        this.endTime = ''
        this.timeSearch()
      }else{
        this.startTime = val[0]
        this.endTime = val[1]
        this.timeSearch()
      }
    },
    timeSearch() {
      timeSearch({
        sttime: this.startTime,
        endtime: this.endTime,
      }).then(res => {
        this.chidren = res.data.data
        this.$emit("passfuntion",this.chidren)
      })
    }
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bigbox {
  // display: flex;
  position: relative;
  height: 120px;
  width: 100%;
  // background-color: #03A9F4;
  // border: 1px solid rgba(11, 85, 140, 0.5);
}
.left {
  width: 500px;
  height: 40px;
  // border: #42b983 1px solid;
  position: absolute;
  top: 45px;
  left: 65px;
  text-align: left;
  line-height: 40px;
  font-size: 20px;
}
.right {
  position: absolute;
  top: 45px;
  right: 105px;
  .date{
      margin-right: 15px;
      /deep/ .el-input__inner{
        height: 32px;
        line-height: 32px;
        color: #fff;
        background-color: transparent;
        border-color: #3453A2;
        .el-range__icon{
          height: auto;
          font-size: 22px;
          color: #03a9f4;
        }
        .el-range-separator{
          height: auto;
          color: #fff;
        }
        .el-range-input{
          color: #fff;
          background-color: transparent;
          cursor: pointer;
        }
        .el-range__close-icon{
          line-height: 24px;
        }
      }
    }
}

</style>
