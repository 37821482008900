<template>
  <!-- 左边 -->
  <div class="bigbox">
    <div class="upbox">
      <div class="upbox_top">
        <div class="upbox_top_title">用户总体信息</div>
        <div class="upbox_top_user">用户总数</div>
        <div class="upbox_top_number">{{ this.all }}</div>
      </div>
      <div class="upbox_center">
        <div class="one">{{ this.generalUser }}</div>
        <div class="two">{{ this.administrativeUnit }}</div>
        <div class="three">{{ this.procurator }}</div>
      </div>
      <div class="upbox_bottom">
        <div class="one">普通用户</div>
        <div class="two">行政单位</div>
        <div class="three">检察院</div>
      </div>
    </div>
    <div class="centerbox">
      <div class="centerbox_top">
        <div class="centerbox_top_title">线索总体信息</div>
        <div class="centerbox_top_user">线索总数</div>
        <div class="centerbox_top_number">{{ this.allClue }}</div>
      </div>
      <div class="centerbox_center">
        <div class="one" @click="viewing">{{ this.end }}</div>
        <div class="one" @click="viewing">{{ this.processed }}</div>
        <div class="one" @click="viewing">{{ this.toBeProcessed }}</div>
        <div class="one" @click="viewing">{{ this.record }}</div>
      </div>
      <div class="centerbox_bottom">
        <div class="one" @click="viewing">未处理</div>
        <div class="one" @click="viewing">已处理</div>
        <div class="one" @click="viewing">已归档</div>
        <div class="one" @click="viewing">成案</div>
      </div>
    </div>
    <div class="belowbox">
      <div class="belowbox_top">
        <div class="belowbox_top_title">线索总体信息曲线图</div>
      </div>
      <div class="belowbox_echarts" id="main"></div>
    </div>
    <div class="content" v-show="recordsWindow == '1'">
      <div class="header">
        <div class="left_oval"></div>
        <div class="oval_title">线索总体信息</div>
        <div class="right_oval"></div>
        <div class="content_cet" @click="closeWindow"></div>
      </div>
      <div class="scontent">
        <ul class="list">
          <li class="line">
            <div class="item">序号</div>
            <div class="item">发布人</div>
            <div class="item">线索类型</div>
            <div class="item">发布地点</div>
            <div class="item">发布时间</div>
            <div class="item">处理情况</div>
            <div class="item">当前节点</div>
          </li>
          <li class="line" v-if="listArr.length == 0">暂无数据~</li>
          <li class="line" v-for="(item, index) in listArr" :key="index" v-else>
            <div class="item">{{ index + 1 }}</div>
            <div class="item">{{ item.userName }}</div>
            <div class="item">{{ item.clueType }}</div>
            <div class="item">{{ item.position }}</div>
            <div class="item">{{ item.dateTime }}</div>
            <div class="item">{{ item.state }}</div>
            <div class="item">{{ item.appraise }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getList,
  getLisA,
  getLisB,
  getLisC,
  getLisD,
  getListClue,
  getListClueA,
  // getListClueB,
} from "@/api/user.js";

export default {
  name: "UserInfo",
  props: {
    msg: String,
  },
  data() {
    return {
      all: "", //用户总数
      administrativeUnit: "", //行政单位
      generalUser: "", //普通用户
      procurator: "", //检察院
      allClue: "", //线索总数
      end: "", //已归档
      processed: "", //已处理
      record: "", //成案
      toBeProcessed: "", //未处理
      listArr: [],
      records: [], //线索总体信息
      recordsWindow: "0", //线索总体信息 窗口
      clueTypeid: [],
      allgetClueA: [], //线索总体 数量
      allgetClueB: [], //未处理 数量
      allgetClueC: [], //已处理 数量
      allgetClueD: [], //成案 数量
    };
  },
  //  async
  mounted() {
    this.getListClue()
    this.getList()
    this.getListClueA()
    // this.getListClueB()
    this.lineChart()
    // const { data : result1 } =  await getLisA();
    // this.allgetClueA = result1.data
    // const { data : result2 } =  await getLisB();
    // this.allgetClueB = result2.data
    // const { data : result3 } =  await getLisC();
    // this.allgetClueC = result3.data
    // const { data : result4 } =  await getLisD();
    // this.allgetClueD = result4.data
    // if(this.allgetClueA.length !== 0 && this.allgetClueB.length !== 0 && this.allgetClueC.length !== 0 && this.allgetClueD.length !== 0 ){
    //this.zhexiantu();
    // }
  },
  methods: {
    getList() {
      getList().then((res) => {
        this.all = res.data.data.all;
        this.administrativeUnit = res.data.data.administrativeUnit;
        this.generalUser = res.data.data.generalUser;
        this.procurator = res.data.data.procurator;
      });
    },
    getListClue() {
      getListClue().then((res) => {
        this.allClue = res.data.data.allClue;
        this.end = res.data.data.end;
        this.record = res.data.data.record;
        this.processed = res.data.data.processed;
        this.toBeProcessed = res.data.data.toBeProcessed;
      });
    },
    // getListClueB() {
    //   getListClueB().then((res) => {
    //     this.clueTypeid = res.data.data;
    //   });
    // },
    
    // 线索总体信息
    getListClueA() {
      // this.getListClueB();
      getListClueA().then((res) => {
        this.listArr = res.data.data.records;
        
        // var clueType = [];
        // this.listArr.forEach((item) => {
        //   this.clueTypeid.forEach((items) => {
        //     if (item.clueType == items.id) {
        //       item.clueType = items.clueType;
        //       clueType.push(item.clueType);
        //     }
        //   });
        // });
      });
    },
    viewing() {
      this.recordsWindow = "1";
    },
    closeWindow() {
      this.recordsWindow = "0";
    },
    // 线索总数
    lineChart() {
        getLisA().then((res) => {
        this.allgetClueA = res.data.data;
        });
        getLisB().then((res) => {
        this.allgetClueB = res.data.data;
        });
        getLisC().then((res) => {
        this.allgetClueC = res.data.data;
        });
        getLisD().then((res) => {
        this.allgetClueD = res.data.data;
        });
        setTimeout( () => {
            this.zhexiantu()
        },1000)
    },
        zhexiantu() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          padding: 10,
          itemGap: 40,
          textStyle: {
            color: "#fff", //选择控件的字体设置
            fontSize: 12,
          },
          icon: "roundRect", //点击 显示 隐藏的图标
          data: ["线索总数", "未处理", "已处理", "成案"],
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              backgroundColor: "#001754",
              // icon: `image://${echartsDown}`,
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,

            data: ["第一周", "第二周", "第三周", "第四周"],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "线索总数",
            type: "line",
            color: "#2FAEFF",
            emphasis: {
              focus: "series",
            },
            data: this.allgetClueA,
            smooth: true,
            areaStyle: {
              //颜色渐变
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(47,174,255,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(47,174,255,0.6)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(47,174,255,0.3)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(47,174,255,0)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
          {
            name: "未处理",
            type: "line",
            color: "#F95C4E",
            emphasis: {
              focus: "series",
            },
            data: this.allgetClueB,
            smooth: true,
            areaStyle: {
              //颜色渐变
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(249,92,78,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(249,92,78,0.6)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(249,92,78,0.3)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(249,92,78,0)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
          {
            name: "已处理",
            type: "line",
            color: "#1FC269 ",
            emphasis: {
              focus: "series",
            },
            data: this.allgetClueC,
            smooth: true,
            areaStyle: {
              //颜色渐变
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(31,194,105,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(31,194,105,0.6)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(31,194,105,0.3)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(31,194,105,0)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
          {
            name: "成案",
            type: "line",
            emphasis: {
              focus: "series",
            },
            color: "#00F9E4",
            data: this.allgetClueD,
            smooth: true,
            areaStyle: {
              //颜色渐变
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0,249,228,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(0,249,228,0.6)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(0,249,228,0.3)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(0,249,228,0)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="less">
.bigbox {
  // display: flex;
  width: 581px;
  height: 930px;
  position: relative;
  // border: 1px greenyellow solid;
}
//  111111
.upbox {
  position: relative;
  width: 581px;
  height: 289px;
  top: -30px;
  background-image: url(~@/assets/images/background/LeftUp.png);
  background-repeat: no-repeat;
  background-position: center;
  .upbox_top {
    position: relative;
    width: 581px;
    height: 130px;
    // border: #42b983 1px solid;
    .upbox_top_title {
      position: absolute;
      width: 100px;
      height: 16px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 30px;
      left: 55px;
    }
    .upbox_top_user {
      position: absolute;
      width: 100px;
      height: 16px;
      font-size: 16px;
      color: #ffffff;
      top: 75px;
      left: 15px;
    }
    .upbox_top_number {
      position: absolute;
      width: 519px;
      height: 20px;
      top: 102px;
      left: 25px;
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
      text-align: right;
      color: #fff;
      background-image: url(~@/assets/images/background/tiao.png);
      transform-style: preserve-3d;
      // text-shadow:1px 1px  red;
      // transform: rotate(45deg);
      transform-origin: 100% 100% 20px;
      animation: glow 3s ease-in-out infinite;
    }
    @keyframes glow {
      // 0%,100%{ text-shadow:0 0 30px #0065fd; }

      // 25%{ text-shadow:0 0 30px #009cff; }

      // 50%{ text-shadow:0 0 30px forestgreen; }

      // 75%{ text-shadow:0 0 30px cyan; }

      0%,
      100% {
        text-shadow: 1px 1px 30px red;
      }

      25% {
        text-shadow: 1px 1px 30px blue;
      }

      50% {
        text-shadow: 1px 1px 30px forestgreen;
      }

      75% {
        text-shadow: 1px 1px 30px cyan;
      }
    }
  }
  .upbox_center {
    position: absolute;
    left: 25px;
    width: 530px;
    height: 90px;
    bottom: 57px;
    display: flex;
    justify-content: space-around;
    .one,
    .two,
    .three {
      // position: absolute;
      width: 100px;
      height: 90px;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      line-height: 50px;
      background-image: url(~@/assets/images/background/blue.png);
    }
  }
  .upbox_bottom {
    position: absolute;
    left: 25px;
    width: 530px;
    height: 20px;
    bottom: 27px;
    display: flex;
    // background-image: url(~@/assets/images/background/blue.png);
    justify-content: space-around;
    .one,
    .two,
    .three {
      width: 100px;
      height: 20px;
      font-size: 14px;
      text-align: center;
    }
  }
}
//  222222
.centerbox {
  position: relative;
  width: 581px;
  height: 289px;
  top: -20px;
  background-image: url(~@/assets/images/background/LeftUp.png);
  background-repeat: no-repeat;
  background-position: center;
  .centerbox_top {
    position: relative;
    width: 581px;
    height: 130px;
    // border: #42b983 1px solid;
    .centerbox_top_title {
      position: absolute;
      width: 100px;
      height: 16px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 30px;
      left: 55px;
    }
    .centerbox_top_user {
      position: absolute;
      width: 100px;
      height: 16px;
      font-size: 16px;
      color: #ffffff;
      top: 75px;
      left: 15px;
    }
    .centerbox_top_number {
      position: absolute;
      width: 519px;
      height: 20px;
      top: 102px;
      left: 25px;
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
      text-align: right;
      color: #fff;
      background-image: url(~@/assets/images/background/tiao.png);
      transform-style: preserve-3d;
      // text-shadow:1px 1px  red;
      // transform: rotate(45deg);
      transform-origin: 100% 100% 20px;
      animation: glow 3s ease-in-out infinite;
    }
    @keyframes glow {
      // 0%,100%{ text-shadow:0 0 30px #0065fd; }

      // 25%{ text-shadow:0 0 30px #009cff; }

      // 50%{ text-shadow:0 0 30px forestgreen; }

      // 75%{ text-shadow:0 0 30px cyan; }

      0%,
      100% {
        text-shadow: 1px 1px 30px red;
      }

      25% {
        text-shadow: 1px 1px 30px blue;
      }

      50% {
        text-shadow: 1px 1px 30px forestgreen;
      }

      75% {
        text-shadow: 1px 1px 30px cyan;
      }
    }
  }
  .centerbox_center {
    position: absolute;
    left: 25px;
    width: 530px;
    height: 90px;
    bottom: 57px;
    display: flex;
    justify-content: space-around;
    .one {
      width: 100px;
      height: 90px;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      line-height: 50px;
      background-image: url(~@/assets/images/background/yellow.png);
    }
  }
  .centerbox_bottom {
    position: absolute;
    left: 25px;
    width: 530px;
    height: 20px;
    bottom: 27px;
    display: flex;
    // background-image: url(~@/assets/images/background/blue.png);
    justify-content: space-around;
    .one {
      width: 100px;
      height: 20px;
      font-size: 14px;
      text-align: center;
    }
  }
}
//  333333
.belowbox {
  position: relative;
  width: 581px;
  height: 340px;
  background-image: url(~@/assets/images/background/LeftDown.png);
  background-repeat: no-repeat;
  background-position: center;
  .belowbox_top {
    position: relative;
    width: 581px;
    height: 50px;
    .belowbox_top_title {
      position: absolute;
      width: 150px;
      height: 25px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 20px;
      left: 55px;
    }
  }
  .belowbox_echarts {
    position: absolute;
    left: 25px;
    bottom: 10px;
    width: 530px;
    height: 270px;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    // border: #42b983 1px solid;
  }
}
.content {
  position: absolute;
  z-index: 999;
  height: calc(100% - 50px);
  width: 250%;
  padding: 0 25px;
  left: 35%;
  top: 1px;
  background-size: 100% 100%;
  background-image: url(~@/assets/images/background/bgc.png);
  overflow: hidden;
  .header {
    height: 100px;
    width: 100%;
    position: relative;
    // border: 1px seagreen solid;
    .left_oval {
      position: absolute;
      width: 106px;
      height: 15px;
      left: 30%;
      top: 49%;
      background-size: 100% 100%;
      background-image: url(~@/assets/images/background/oval-shaped2.png);
    }
    .right_oval {
      position: absolute;
      width: 106px;
      height: 15px;
      right: 30%;
      top: 49%;
      background-size: 100% 100%;
      background-image: url(~@/assets/images/background/oval-shaped.png);
    }
    .oval_title {
      position: absolute;
      width: 168px;
      height: 30px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto auto;
      text-align: center;
      font-size: 28px;
    }
    .content_cet {
      position: absolute;
      width: 68px;
      height: 68px;
      right: 10px;
      top: 20px;
      text-align: center;
      font-size: 28px;
      background-size: 100% 100%;
      background-image: url(~@/assets/images/background/close_xiao.png);
    }
  }
  .scontent {
    position: absolute;
    overflow: 0 auto;
    z-index: 9999;
    width: 96%;
    height: 85%;
    // border: 1px solid	#FF8C00;
    .list {
      width: 100%;
      height: 750px;
      // border: 1px solid #03A9F4;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 2px;
        height: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #03a9f4;
      }
      &::-webkit-scrollbar-track {
        background-color: #16307a;
      }
      .line {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 18px;
        text-align: center;
        &:nth-child(2n + 1) {
          background-color: rgba(3, 57, 139, 0.55);
        }
        &:first-child {
          background-color: rgba(3, 57, 139, 0.9);
        }
        .item {
          font-size: 16px;
          flex: 1;
          &:nth-child(4) {
            flex: 2;
          }
          &:nth-child(3) {
            flex: 2;
          }
        }
      }
    }
  }
}
</style>
