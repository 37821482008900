// const devBaseURL = "http://192.168.0.176:9001/";// 开发环境
// const proBaseURL = "http://192.168.0.176:9001/";// 生产环境

// const devBaseURL = "datashow.lziit.cn";// 开发环境
// const proBaseURL = "datashow.lziit.cn";// 生产环境

const devBaseURL = "https://jyjc.api.lziit.cn/";// 开发环境
const proBaseURL = "https://jyjc.api.lziit.cn/";// 生产环境


// 判断当前的环境
export const BASE_URL = process.env.NODE_ENV === "development" ? devBaseURL : proBaseURL;
// 请求超时
export const TIMEOUT = 5000;                     