<template>
  <!-- 中间 -->
  <div class="bigbox">
    <!-- 上 -->
    <div class="upbox">
      <div class="upbox_top">
        <div class="upbox_top_title">
          <!-- <div class="pull_box"></div> -->
          <el-dropdown
            trigger="click"
            @command="handleAommand"
            @visible-change="anniuc"
          >
            <span class="el-dropdown-link">
              {{ this.menu }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">{{
                this.ListypeExamineA
              }}</el-dropdown-item>
              <el-dropdown-item command="b">{{
                this.ListypeExamineB
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="pull_down" v-show="valuetypa"></div>
        <div class="pull_up" v-show="!valuetypa"></div>
      </div>
      <div class="curtain"></div>
      <div class="upbox_bottom" id="mains"></div>
    </div>
    <!-- 中  -->
    <div class="centerbox">
      <div class="centerbox_top">
        <div class="centerbox_top_title">
          <el-dropdown
            trigger="click"
            @command="handleBommand"
            @visible-change="anniud"
          >
            <span class="el-dropdown-link">
              {{ this.menuB }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="c">{{
                this.listBzhuA
              }}</el-dropdown-item>
              <el-dropdown-item command="d">{{
                this.listBzhuB
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="pull_down" v-show="valuetypb"></div>
        <div class="pull_up" v-show="!valuetypb"></div>
      </div>
      <div class="curtainB"></div>
      <div class="centerbox_bottom" id="maintwo"></div>
    </div>
    <!-- 下 -->
    <div class="belowbox">
      <div class="belowbox_top">
        <div class="belowbox_top_title">
          <el-dropdown
            trigger="click"
            @command="handleCommand"
            @visible-change="anniue"
          >
            <span class="el-dropdown-link">
              行政公益诉讼-{{ this.menuC }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="e"
                >行政公益诉讼-{{ this.listCzhuA }}</el-dropdown-item
              >
              <el-dropdown-item command="f"
                >行政公益诉讼-{{ this.listCzhuB }}</el-dropdown-item
              >
              <el-dropdown-item command="g"
                >行政公益诉讼-{{ this.listCzhuC }}</el-dropdown-item
              >
              <el-dropdown-item command="h"
                >行政公益诉讼-{{ this.listCzhuD }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="pull_down" v-show="valuetypc"></div>
        <div class="pull_up" v-show="!valuetypc"></div>
      </div>
      <div class="curtainC"></div>
      <div class="belowbox_bottom" id="mainthree"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
// 中间
import {
  getListypeA,
  getListypeB,
  getListypeC,
  // getHistogramA,
  // getHistogramB,
  // getHistogramC,
} from "@/api/user.js";
export default {
  name: "MiddleScent",
  props: {
    msg: String,
    table6: Array,
    table7: Array,
    table8: Array,
    table9: Array,
    table10: Array,
    table11: Array,
    table12: Array,
    table13: Array,
  },
  data() {
    return {
      valuetypa: true,
      valuetypb: true,
      valuetypc: true,
      ListypeExamineA: "", //公益诉讼检察
      ListypeExamineB: "", //其他检察
      menu: "公益诉讼检察",
      data1: [], //公益诉讼检察 shuju
      data2: [], //其他检察     shuju
      dataAxis: ["民事公益诉讼", "行政公益诉讼"], //公益诉讼检察、其他检察   x轴

      listBzhuA: "", //民事公益诉讼
      listBzhuB: "", //行政公益诉讼
      menuB: "民事公益诉讼",
      data3: [], //民事公益诉讼 shuju
      data4: [], //行政公益诉讼 数据
      dataAxisB: [
        "生态环境...",
        "资源保护",
        "食品药品...",
        "英雄烈士...",
        "其他",
      ],

      listCzhuA: "", //行政公益诉讼-食品安全
      listCzhuB: "", //行政公益诉讼-药品安全
      listCzhuC: "", //行政公益诉讼-国有财产保护
      listCzhuD: "", //行政公益诉讼-国有土地使用权出让
      menuC: "食品安全",
      dataAxisC: [
        "食品生产...",
        "食品销售...",
        "食品添加...",
        "食品相关...",
        "食品的贮...",
        "其他",
      ],
      data5: [], //
    };
  },
  watch: {
    table6: function () {
      const name =[]
          const sum =[]
          this.table6.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxis = name
          this.data1 = sum  
          this.zhuzhuangtu()
    },
    table7: function () {
    },
    table8: function () {
      const name =[]
          const sum =[]
          this.table8.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisB = name
          for(var j = 0;j < this.dataAxisB.length;j++) {
            if(this.dataAxisB[j].length > 4) {
              this.dataAxisB[j] = this.dataAxisB[j].substring(0,4)+"..."
            }
          }
          this.data3 = sum  
          this.zhuzhuangtutwo()
    },
    table9: function () {
    },
    table10: function () {
      const name =[]
          const sum =[]
          this.table10.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisC = name
          for(var l = 0;l < this.dataAxisC.length;l++) {
            if(this.dataAxisC[l].length > 4) {
              this.dataAxisC[l] = this.dataAxisC[l].substring(0,4)+"..."
            }
          }
          this.data5 = sum  
          this.zhuzhuangtuthree()
    },
    table11: function () {
    },
    table12: function () {
    },
    table13: function () {
    },
  },
  mounted() {
    // this.zhuzhuangtuthree();
    // this.getHistogramA();
    // this.getHistogramB();
    // this.getHistogramC();
    this.getListypeA();
    this.getListypeB();
    this.getListypeC();
  },
  methods: {
    // 柱 1 选择名称
    getListypeA() {
      getListypeA().then((res) => {
        this.ListypeExamineA = res.data.data[0].clueType;
        this.ListypeExamineB = res.data.data[1].clueType;
      });
    },
    // 柱 1 数据
    // getHistogramA() {
    //   getHistogramA().then((res) => {
    //     this.data1 = res.data.data[1];
    //     this.zhuzhuangtu();
    //   });
    // },
    // 柱 1 选择器
    handleAommand(command) {
      if (command == "a") {
        this.menu = this.ListypeExamineA;
        // this.dataAxis = ["民事公益诉讼", "行政公益诉讼"];
          const name =[]
          const sum =[]
          this.table6.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxis = name
          // for(var i = 0;i < this.dataAxis.length;i++) {
          //   if(this.dataAxis[i].length > 4) {
          //     this.dataAxis[i] = this.dataAxis[i].substring(0,4)+"..."
          //   }
          // }
          this.data1 = sum  
          this.zhuzhuangtu()
      } else if (command == "b") {
        this.menu = this.ListypeExamineB;
        // this.dataAxis = ["民事检察", "行政检察", "刑事检察"];
          const name =[]
          const sum =[]
          this.table7.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxis = name
          // for(var i = 0;i < this.dataAxis.length;i++) {
          //   if(this.dataAxis[i].length > 4) {
          //     this.dataAxis[i] = this.dataAxis[i].substring(0,4)+"..."
          //   }
          // }
          this.data1 = sum  
          this.zhuzhuangtu()
        // getHistogramA().then((res) => {
        //   this.data1 = res.data.data[2];
        //   this.zhuzhuangtu();
        // });
      }
    },
    anniuc() {
      // this.valuetypa = !this.valuetypa
    },
    anniud() {
      // this.valuetypb = !this.valuetypb
    },
    anniue() {
      // this.valuetypc = !this.valuetypc
    },
    // 柱状图1
    zhuzhuangtu() {
      var chartDom = document.getElementById("mains");
      var myChart = echarts.init(chartDom);
      var option;
      // let yMax = 500;
      // let dataShadow = [];
      // for (let i = 0; i < data.length; i++) {
      //   dataShadow.push(yMax);
      // }
      option = {
        title: {},
        grid: {
          // 控制 整体图形的 上下左右大小
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          data: this.dataAxis,
          // position:'bottom',  //x轴 data位置
          offset: 20, //向下偏移的量
          axisLabel: {
            color: "#ccc",
            interval: 0,
            inside: false,
            // rotate: -50,
            // margin: 10,
            // width: 40,
            // formatter: function (value) {
            //   return value.split("").join("\n");
            // },
          },
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          splitLine: {
            show: false, // 不显示网格线
          },
          z: 10,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#14487B",
            },
          },
          axisLabel: {
            color: "#ccc",
            fontSize: 12,
          },
          // max: function (data) {
          //   return data.max;
          // },
        },
        // dataZoom: [    //控制缩放
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            type: "bar",
            barWidth: 19,
            label: {
              color: "#fff",
              show: true,
              position: "top",
            },
            // showBackground: true,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(1,174,247,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(1,174,247,0.7)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(31,97,234,0.4)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(19,57,136,0.4)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
            data: this.data1,
          },
          {
            type: "pictorialBar",
            symbolSize: [19, 6],
            symbolOffset: [0, -4],
            z: 12,
            itemStyle: { color: "#21F3FF" },
            symbolPosition: "end",
            data: this.data1,
          },
          {
            type: "pictorialBar",
            symbolSize: [20, 6],
            symbolOffset: [0, 3],
            z: 12,
            itemStyle: { color: "#133988" },
            symbolPosition: "start",
            data: this.data1,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 柱 2 选择名称
    getListypeB() {
      getListypeB().then((res) => {
        this.listBzhuA = res.data.data[0].clueType;
        this.listBzhuB = res.data.data[1].clueType;
      });
    },
    // 柱 2 数据
    // getHistogramB() {
    //   getHistogramB().then((res) => {
    //     this.data3 = res.data.data[1];
    //     this.zhuzhuangtutwo();
    //   });
    // },
    // 柱 2 选择器
    handleBommand(command) {
      if (command == "c") {
        this.menuB = this.listBzhuA;
        // this.dataAxisB = [
        //   "生态环境...",
        //   "资源保护",
        //   "食品药品...",
        //   "英雄烈士...",
        //   "其他",
        // ];
          const name =[]
          const sum =[]
          this.table8.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisB = name
          for(var j = 0;j < this.dataAxisB.length;j++) {
            if(this.dataAxisB[j].length > 4) {
              this.dataAxisB[j] = this.dataAxisB[j].substring(0,4)+"..."
            }
          }
          this.data3 = sum  
          this.zhuzhuangtutwo()
        // getHistogramB().then((res) => {
        //   this.data3 = res.data.data[1];
        //   this.zhuzhuangtutwo();
        // });
      } else if (command == "d") {
        this.menuB = this.listBzhuB;
        // this.dataAxisB = [
        //   "生态环境...",
        //   "资源保护",
        //   "食品安全",
        //   "药品安全",
        //   "国有财产...",
        //   "国有土地...",
        //   "英雄烈士...",
        //   "未成年人...",
        //   "安全生产",
        //   "军人地位...",
        //   "个人信息...",
        //   "其他",
        // ];
        const name =[]
          const sum =[]
          this.table9.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisB = name
          for(var k = 0;k < this.dataAxisB.length;k++) {
            if(this.dataAxisB[k].length > 4) {
              this.dataAxisB[k] = this.dataAxisB[k].substring(0,4)+"..."
            }
          }
          this.data3 = sum  
          this.zhuzhuangtutwo()
        // getHistogramB().then((res) => {
        //   this.data3 = res.data.data[2];
        //   this.zhuzhuangtutwo();
        // });
      }
    },
    zhuzhuangtutwo() {
      var chartDom = document.getElementById("maintwo");
      var myChart = echarts.init(chartDom);
      var option;
      // let yMax = 500;
      // let dataShadow = [];
      // for (let i = 0; i < data.length; i++) {
      //   dataShadow.push(yMax);
      // }
      option = {
        title: {},
        grid: {
          // 控制 整体图形的 上下左右大小
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          data: this.dataAxisB,
          // position:'bottom',  //x轴 data位置
          offset: 20, //向下偏移的量
          axisLabel: {
            color: "#ccc",
            interval: 0,
            inside: false,
            rotate: -50,
            // margin: 10,
            // formatter: function (value) {
            //   return value.split("").join("\n");
            // },
          },
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          splitLine: {
            show: false, // 不显示网格线
          },
          z: 10,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#14487B",
            },
          },
          axisLabel: {
            color: "#ccc",
            fontSize: 12,
          },
          max: function (data) {
            return data.max;
          },
        },
        // dataZoom: [    //控制缩放
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            type: "bar",
            barWidth: 19,
            label: {
              color: "#fff",
              show: true,
              position: "top",
            },
            // showBackground: true,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(1,174,247,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(1,174,247,0.7)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(31,97,234,0.4)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(19,57,136,0.4)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
            data: this.data3,
          },
          {
            type: "pictorialBar",
            symbolSize: [19, 6],
            symbolOffset: [0, -4],
            z: 12,
            itemStyle: { color: "#21F3FF" },
            symbolPosition: "end",
            data: this.data3,
          },
          {
            type: "pictorialBar",
            symbolSize: [20, 6],
            symbolOffset: [0, 3],
            z: 12,
            itemStyle: { color: "#133988" },
            symbolPosition: "start",
            data: this.data3,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    // -----------------------------------------------------------------------------------------------------------------------------------------------
    // 柱 3
    getListypeC() {
      getListypeC().then((res) => {
        this.listCzhuA = res.data.data[0].clueType;
        this.listCzhuB = res.data.data[1].clueType;
        this.listCzhuC = res.data.data[2].clueType;
        this.listCzhuD = res.data.data[3].clueType;
      });
    },
    // getHistogramC() {
    //   getHistogramC().then((res) => {
    //     this.data5 = res.data.data[1];
    //     this.zhuzhuangtuthree();
    //   });
    // },
    handleCommand(command) {
      if(command == "e") {
        this.menuC = this.listCzhuA;
        const name =[]
          const sum =[]
          this.table10.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisC = name
          for(var l = 0;l < this.dataAxisC.length;l++) {
            if(this.dataAxisC[l].length > 4) {
              this.dataAxisC[l] = this.dataAxisC[l].substring(0,4)+"..."
            }
          }
          this.data5 = sum  
          this.zhuzhuangtuthree()
      }else if (command == "f") {
          this.menuC = this.listCzhuB;
          const name =[]
          const sum =[]
          this.table11.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisC = name
          for(var m = 0;m < this.dataAxisC.length;m++) {
            if(this.dataAxisC[m].length > 4) {
              this.dataAxisC[m] = this.dataAxisC[m].substring(0,4)+"..."
            }
          }
          this.data5 = sum  
          this.zhuzhuangtuthree()
      }else if (command == "g") {
          this.menuC = this.listCzhuC;
          const name =[]
          const sum =[]
          this.table12.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisC = name
          for(var n = 0;n < this.dataAxisC.length;n++) {
            if(this.dataAxisC[n].length > 4) {
              this.dataAxisC[n] = this.dataAxisC[n].substring(0,4)+"..."
            }
          }
          this.data5 = sum  
          this.zhuzhuangtuthree()

      }else if (command == "h") {
          this.menuC = this.listCzhuD;
          const name =[]
          const sum =[]
          this.table13.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisC = name
          for(var o = 0;o < this.dataAxisC.length;o++) {
            if(this.dataAxisC[o].length > 4) {
              this.dataAxisC[o] = this.dataAxisC[o].substring(0,4)+"..."
            }
          }
          this.data5 = sum  
          this.zhuzhuangtuthree()

      }
    },
    zhuzhuangtuthree() {
      var chartDom = document.getElementById("mainthree");
      var myChart = echarts.init(chartDom);
      var option;
      // let yMax = 500;
      // let dataShadow = [];
      // for (let i = 0; i < data.length; i++) {
      //   dataShadow.push(yMax);
      // }
      option = {
        title: {},
        grid: {
          // 控制 整体图形的 上下左右大小
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          data: this.dataAxisC,
          // position:'bottom',  //x轴 data位置
          offset: 20, //向下偏移的量
          axisLabel: {
            color: "#ccc",
            interval: 0,
            inside: false,
            rotate: -50,
            // margin: 10,
            // width: 40,
            // formatter: function (value) {
            //   return value.split("").join("\n");
            // },
          },
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          splitLine: {
            show: false, // 不显示网格线
          },
          z: 10,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisLabel: {
            color: "#ccc",
            fontSize: 12,
          },
          max: function (data) {
            return data.max;
          },
        },
        // dataZoom: [    //控制缩放
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            type: "bar",
            label: {
              color: "#fff",
              show: true,
              position: "top",
            },
            // showBackground: true,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(1,174,247,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(1,174,247,0.7)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(31,97,234,0.4)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(19,57,136,0.4)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
            data: this.data5,
          },
          {
            type: "pictorialBar",
            symbolSize: [19, 6],
            symbolOffset: [0, -4],
            z: 12,
            itemStyle: { color: "#21F3FF" },
            symbolPosition: "end",
            data: this.data5,
          },
          {
            type: "pictorialBar",
            symbolSize: [20, 6],
            symbolOffset: [0, 3],
            z: 12,
            itemStyle: { color: "#133988" },
            symbolPosition: "start",
            data: this.data5,
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="less">
.bigbox {
  // display: flex;
  width: 681px;
  height: 930px;
}
.upbox {
  width: 680px;
  height: 285px;
  margin-bottom: 12px;
  background-image: url(~@/assets/images/background/CenterUp.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .upbox_top {
    position: relative;
    width: 680px;
    height: 60px;
    // border: #42b983 1px solid;
    .upbox_top_title {
      position: absolute;
      overflow: hidden;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 25px;
      left: 60px;
      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
      }
      .el-icon-arrow-down {
        font-size: 16px;
      }
    }
    .pull_up {
      position: relative;
      left: 160px;
      // z-index: 999;
      width: 20px;
      height: 70px;
      // border: 1px seagreen solid;
      background-image: url(~@/assets/images/background/tab_right.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .pull_down {
      position: relative;
      left: 160px;
      // z-index: 999;
      width: 20px;
      height: 70px;
      // border: 1px seagreen solid;
      background-image: url(~@/assets/images/background/tab_pull_down.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .curtain {
    position: absolute;
    left: 67px;
    bottom: 25px;
    height: 60px;
    width: 580px;
    // border: 1px salmon solid;
    background-image: url(~@/assets/images/background/zu3.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .upbox_bottom {
    position: relative;
    left: 20px;
    width: 640px;
    height: 215px;
    // border: #42b983 1px solid;
  }
}
.centerbox {
  width: 680px;
  height: 310px;
  margin-bottom: 10px;
  background-image: url(~@/assets/images/background/CenterUp.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .centerbox_top {
    position: relative;
    width: 680px;
    height: 70px;
    // border: #42b983 1px solid;
    .centerbox_top_title {
      position: absolute;
      overflow: hidden;
      // width: 130px;
      // height: 200px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 35px;
      left: 60px;
      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
      }
      .el-icon-arrow-down {
        font-size: 16px;
      }
    }
    .pull_up {
      position: relative;
      left: 160px;
      // z-index: 999;
      width: 20px;
      height: 90px;
      background-image: url(~@/assets/images/background/tab_right.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .pull_down {
      position: relative;
      left: 160px;
      // z-index: 999;
      width: 20px;
      height: 90px;
      background-image: url(~@/assets/images/background/tab_pull_down.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .curtainB {
    position: absolute;
    left: 67px;
    bottom: 60px;
    height: 60px;
    width: 580px;
    // border: 1px salmon solid;
    background-image: url(~@/assets/images/background/zu3.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .centerbox_bottom {
    position: relative;
    left: 20px;
    width: 640px;
    height: 230px;
    // border: #42b983 1px solid;
  }
}
.belowbox {
  width: 680px;
  height: 310px;
  background-image: url(~@/assets/images/background/CenterBelow.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .belowbox_top {
    position: relative;
    width: 680px;
    height: 70px;
    // border: #42b983 1px solid;
    .belowbox_top_title {
      position: absolute;
      overflow: hidden;
      text-align: center;
      // width: 130px;
      // height: 200px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 35px;
      left: 60px;
      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
      }
      .el-icon-arrow-down {
        font-size: 16px;
      }
    }
    .pull_up {
      position: relative;
      left: 310px;
      // z-index: 999;
      width: 20px;
      height: 90px;
      background-image: url(~@/assets/images/background/tab_right.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .pull_down {
      position: relative;
      left: 310px;
      // z-index: 999;
      width: 20px;
      height: 90px;
      background-image: url(~@/assets/images/background/tab_pull_down.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .curtainC {
    position: absolute;
    left: 67px;
    bottom: 60px;
    height: 60px;
    width: 580px;
    // border: 1px salmon solid;
    background-image: url(~@/assets/images/background/zu3.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .belowbox_bottom {
    position: relative;
    left: 20px;
    width: 640px;
    height: 230px;
    // border: #42b983 1px solid;
  }
}
</style>
