<template>
  <!-- 右边 -->
  <div class="bigbox">
    <!-- 上 -->
    <div class="upbox">
      <div class="upbox_top">
        <div class="upbox_top_title">行政报备总体信息</div>
        <div class="upbox_top_user">后台用户总数</div>
        <div class="upbox_top_number">{{ this.allClue }}</div>
      </div>
      <div class="upbox_center">
        <div class="one">{{ this.toBeProcessed }}</div>
        <div class="two">{{ this.processed }}</div>
        <div class="three">{{ this.end }}</div>
        <!-- <div class="three">{{ this.record }}</div> -->
      </div>
      <div class="upbox_bottom">
        <div class="one">报备总数</div>
        <div class="two">行政处罚</div>
        <div class="three">行政许可</div>
        <!-- <div class="three">成案</div> -->
      </div>
    </div>
    <!-- 中 -->
    <div class="centerbox">
      <div class="centerbox_top">
        <div class="centerbox_top_title">
            <el-dropdown trigger="click" @command="handleBommans">
            <span class="el-dropdown-link">
              {{ this.menuS }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="c">{{
                this.listDzhuC
              }}</el-dropdown-item>
              <el-dropdown-item command="d">{{
                this.listDzhuD
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="pull_down" v-show="valuetype"></div>
        <div class="pull_up" v-show="!valuetype"></div>
      </div>
      <div class="centerbox_echarts">
        <div id="containera" class="container"></div>
      </div>
      <div class="centerbox_echarta">
        <div id="containers" class="container"></div>
      </div>
    </div>
    <!-- 下 -->
    <div class="belowbox">
      <div class="belowbox_top">
        <div class="belowbox_top_title">
          <el-dropdown trigger="click" @command="handleBommand" >
            <span class="el-dropdown-link">
              {{ this.menuD }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">{{
                this.listDzhuA
              }}</el-dropdown-item>
              <el-dropdown-item command="b">{{
                this.listDzhuB
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="pull_down" v-show="valuetyps"></div>
        <div class="pull_up" v-show="!valuetyps"></div>
      </div>
      <div class="curtainD"></div>
      <div class="belowbox_op"> 
        <div class="belowbox_bottom" id="mainfour"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import Highcharts3D from "highcharts/highcharts-3d";

HighchartsMore(Highcharts);
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);
  // getExecutive,

import {
  getListypeD,
  // getExWangA,
} from "@/api/user.js";

export default {
  name: "ReportLicensing",

  props: {
    msg: String,
    table1: Object,
    table2: Object,
    table3: Array,
    table4: Array,
    table5: Array,
  },
  data() {
    return {
      stockChart: null,
      type: "3dpie",
      name: "3D 饼图",
      code: "3dpie",
      chart: null,
      // id:'test',
      value: "",
      valuetype: true,
      valuetyps: true,
      // 行政报备总体信息
      end: "",
      record: "",
      allClue: "",
      processed: "",
      toBeProcessed: "",
      menuD: "行政许可",
      menuS: "行政许可",
      listDzhuA: "", //行政许可
      listDzhuB: "", //行政处罚
      listDzhuC: "", //行政许可
      listDzhuD: "", //行政处罚
      titlesA:[],
      dataAxisD: [
        "九原区人...",
        "公安局",
        "法院",
        "税务局",
        "消防队",
        "环保局",
        "时长监督...",
      ],
      dataAdministrative: [],
      datas:[],
      datab:[],
      yidu:'',
      weidu:'',
      yixuan:'',
      weixuan:'',
    };
  },
  watch: {
      table1:function() {
        this.menuD = '行政许可';
        this.titlesA = this.table1.data
        const name =[]
          const sum =[]
          this.titlesA.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisD = name
          for(var i = 0;i < this.dataAxisD.length;i++) {
            if(this.dataAxisD[i].length > 4) {
              this.dataAxisD[i] = this.dataAxisD[i].substring(0,4)+"..."
            }
          }
          this.dataAdministrative = sum
          this.zhuzhuangtufour();
      },
      table2:function() {
      },
      table3:function() {
        const name =[]
        const sum =[]
          this.table3.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
        this.allClue = sum[0]
        this.toBeProcessed = sum[1]
        this.processed  = sum[2]
        this.end = sum[3]
      },
      table4:function() {
        this.weidu = this.table4[0].sum
          this.yidu = this.table4[1].sum
          this.weixuan = this.table4[2].sum
          this.yixuan = this.table4[3].sum
          this.datas = [
          {
            name: "已读",
            y: this.yidu,
            color: "#33E0FE",
          },
          {
            name: "未读",
            y: this.weidu,
            sliced: true,
            selected: true,
            color: "#FF8C00",
          },
            ];
            this.datab = [
          {
            name: "已选",
            y: this.yixuan,
            color: "#33E0FE",
          },
          {
            name: "未选",
            y: this.weixuan,
            sliced: true,
            selected: true,
            color: "#F9CA72",
          },
            ];
            this.zhexiantua()
            this.zhexiantub()
      },
      table5:function() {
      },
      
  },

  mounted() {
    this.getListypeD();
    // this.getExWangA();
  },

  methods: {
    handleBommand(command) {
      if (command == "a") {
        this.menuD = this.listDzhuA;
        this.titlesA = this.table1.data
        const name =[]
          const sum =[]
          this.titlesA.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisD = name
          for(var i = 0;i < this.dataAxisD.length;i++) {
            if(this.dataAxisD[i].length > 4) {
              this.dataAxisD[i] = this.dataAxisD[i].substring(0,4)+"..."
            }
          }
          this.dataAdministrative = sum
          this.zhuzhuangtufour();
        
      } else if (command == "b") {
        this.menuD = this.listDzhuB;
        this.titlesA = this.table2.data;
          const name =[]
          const sum =[]
          this.titlesA.forEach((item)=>{
            name.push(item.name)
            sum.push(item.sum)
          })
          this.dataAxisD = name
          for(var j = 0;j < this.dataAxisD.length;j++) {
            if(this.dataAxisD[j].length > 4) {
              this.dataAxisD[j] = this.dataAxisD[j].substring(0,4)+"..."
            }
          }
          this.dataAdministrative = sum
          this.zhuzhuangtufour();
      }
    },
    handleBommans(command)  {
      if (command == "c") {
          this.menuS = this.listDzhuC;
          this.weidu = this.table4[0].sum
          this.yidu = this.table4[1].sum
          this.weixuan = this.table4[2].sum
          this.yixuan = this.table4[3].sum
          this.datas = [
          {
            name: "已读",
            y: this.yidu,
            color: "#33E0FE",
          },
          {
            name: "未读",
            y: this.weidu,
            sliced: true,
            selected: true,
            color: "#FF8C00",
          },
            ];
            this.datab = [
          {
            name: "已选",
            y: this.yixuan,
            color: "#33E0FE",
          },
          {
            name: "未选",
            y: this.weixuan,
            sliced: true,
            selected: true,
            color: "#F9CA72",
          },
            ];
            this.zhexiantua()
            this.zhexiantub()
      }else if (command == "d")  {
        this.menuS = this.listDzhuD;
        this.weidu = this.table5[0].sum
          this.yidu = this.table5[1].sum
          this.weixuan = this.table5[2].sum
          this.yixuan = this.table5[3].sum
          this.datas = [
          {
            name: "已读",
            y: this.yidu,
            color: "#33E0FE",
          },
          {
            name: "未读",
            y: this.weidu,
            sliced: true,
            selected: true,
            color: "#FF8C00",
          },
            ];
            this.datab = [
          {
            name: "已选",
            y: this.yixuan,
            color: "#F9CA72",
          },
          {
            name: "未选",
            y: this.weixuan,
            sliced: true,
            selected: true,
            color: "#33E0FE",
          },
            ];
            this.zhexiantua()
            this.zhexiantub()
        //   getExWangA({
        //     resid: '1'
        //   }).then( (res) => {
        //     this.datas = [
        //   {
        //     name: "已读",
        //     y: res.data.data.Read,
        //     color: "#33E0FE",
        //   },
        //   {
        //     name: "未读",
        //     y: res.data.data.noRead,
        //     sliced: true,
        //     selected: true,
        //     color: "#F9CA72",
        //   },
        // ];
        // this.datab = [
        //   {
        //     name: "已选",
        //     y: res.data.data.Selected,
        //     color: "#33E0FE",
        //   },
        //   {
        //     name: "未选",
        //     y: res.data.data.noSelected,
        //     sliced: true,
        //     selected: true,
        //     color: "#F9CA72",
        //   },
        // ];
        // this.zhexiantua()
        // this.zhexiantub()
        //   })
      }
    },
    // anniua() {
    //     this.valuetype = !this.valuetype
    // },
    // anniub() {
    //     this.valuetyps = !this.valuetyps
    // },
    // getExWangA() {
    //   getExWangA({
    //         resid: '0'
    //       }).then( (res) => {
    //         this.datas = [
    //       {
    //         name: "已读",
    //         y: res.data.data.Read,
    //         color: "#33E0FE",
    //       },
    //       {
    //         name: "未读",
    //         y: res.data.data.noRead,
    //         sliced: true,
    //         selected: true,
    //         color: "#FF8C00",
    //       },
    //         ];
    //         this.datab = [
    //       {
    //         name: "已选",
    //         y: res.data.data.Selected,
    //         color: "#42ec9d",
    //       },
    //       {
    //         name: "未选",
    //         y: res.data.data.noSelected,
    //         sliced: true,
    //         selected: true,
    //         color: "#F9CA72",
    //       },
    //         ];
    //         this.zhexiantua()
    //         this.zhexiantub()
    //       })
    // },
    getListypeD() {
      getListypeD().then((res) => {
        this.listDzhuA = res.data.data[0].clueType;
        this.listDzhuB = res.data.data[1].clueType;
        this.listDzhuC = res.data.data[0].clueType;
        this.listDzhuD = res.data.data[1].clueType;
      });
    },
    // getHistogramD() {
    //   getHistogramD().then((res) => {
    //     this.dataAdministrative = res.data.data[1];
    //     this.zhuzhuangtufour();
    //   });
    // },

    zhexiantua() {
      Highcharts.chart("containera", {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
          backgroundColor: "none",
        },
        title: {
          floating: true,
          x: -50,
          useHTML: true,
          text: ".",
        },
        tooltip: {
          pointFormat: '{point.percentage:.1f}%',
          style: {
            fontSize: 14,
            color: "#000",
          },
        },
        credits: {
          enabled: false, //禁用版权url    此处不设置，会显示highecharts.com
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            // color: pieColors,
            // center: ['50%', '55%'],
            // showInLegend: true,
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        },
        series: [
          {
            type: "pie",
            // name: "浏览器占比",
            data: this.datas
          },
        ],
      });
    },
    zhexiantub() {
      Highcharts.chart("containers", {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
          backgroundColor: "none",
        },

        title: {
          floating: true,
          x: -50,
          useHTML: true,
          text: ".",
        },
        tooltip: {
          pointFormat: '{point.percentage:.1f}%',
          style: {
            fontSize: 14,
            color: "#000",
          },
        },
        // colors: ['#33E0FE', '#4178F3'],
        credits: {
          enabled: false, //禁用版权url    此处不设置，会显示highecharts.com
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            // center: ['50%', '55%'],
            // showInLegend: true,
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        },
        series: [
          {
            type: "pie",
            // name: "浏览器占比",
            data: this.datab
            //  [
            //   {
            //     name: "Chrome",
            //     y: 12,
            //     sliced: true,
            //     selected: true,
            //     color: "#33E0FE",
            //   },
            //   {
            //     name: "Opera",
            //     y: 6,
            //     color: "#F9CA72",
            //   },
            //   // [name:"Opera", y: 6,color:'#F9CA72'],
            //   // ["Others", 0.7],
            // ],
          },
        ],
      });
    },
    // 柱状图4
    zhuzhuangtufour() {
      var chartDom = document.getElementById("mainfour");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {},
        grid: {
          // 控制 整体图形的 上下左右大小
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          data: this.dataAxisD,
          // position:'bottom',  //x轴 data位置
          offset: 15, //向下偏移的量
          axisLabel: {
            color: "#ccc",
            interval: 0,
            inside: false,
            rotate: -50,
            // margin: 10,
            // width: 10,
            // formatter: function (value) {
            //   return value.split("").join("\n");
            // },
          },
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          splitLine: {
            show: false, // 不显示网格线
          },
          z: 10,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisLabel: {
            color: "#ccc",
            fontSize: 12,
          },
          max: function (data) {
            return data.max;
          },
        },
        // dataZoom: [    //控制缩放
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            type: "bar",
            label: {
              color: "#fff",
              show: true,
              position: "top",
            },
            // showBackground: true,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(1,174,247,1)", // 0% 处的颜色
                  },
                  {
                    offset: 0.3,
                    color: "rgba(1,174,247,0.7)", // 100% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(31,97,234,0.4)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(19,57,136,0.4)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
            data: this.dataAdministrative,
          },
          {
            type: "pictorialBar",
            symbolSize: [19, 6],
            symbolOffset: [0, -4],
            z: 12,
            itemStyle: { color: "#21F3FF" },
            symbolPosition: "end",
            data: this.dataAdministrative,
          },
          {
            type: "pictorialBar",
            symbolSize: [20, 6],
            symbolOffset: [0, 3],
            z: 12,
            itemStyle: { color: "#133988" },
            symbolPosition: "start",
            data: this.dataAdministrative,
          },
        ],
      };
      // const zoomSize = 6;
      // myChart.on("click", function (params) {
      //   myChart.dispatchAction({
      //     type: "dataZoom",
      //     startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
      //     endValue:
      //       dataAxis[
      //         Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
      //       ],
      //   });
      // });

      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="less">
.bigbox {
  // display: flex;
  width: 581px;
  height: 930px;
  position: relative;
}
.upbox {
  position: relative;
  width: 580px;
  height: 289px;
  top: -40px;
  background-image: url(~@/assets/images/background/RightUp.png);
  background-repeat: no-repeat;
  background-position: center;
  .upbox_top {
    position: relative;
    width: 581px;
    height: 130px;
    .upbox_top_title {
      position: absolute;
      width: 130px;
      height: 16px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 30px;
      left: 55px;
    }
    .upbox_top_user {
      position: absolute;
      width: 100px;
      height: 16px;
      font-size: 16px;
      color: #ffffff;
      top: 75px;
      left: 15px;
    }
    .upbox_top_number {
      position: absolute;
      width: 519px;
      height: 20px;
      top: 102px;
      left: 25px;
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
      text-align: right;
      color: #fff;
      background-image: url(~@/assets/images/background/tiao.png);
      transform-style: preserve-3d;
      // transform: rotate(45deg);
      transform-origin: 100% 100% 20px;
      animation: glow 3s ease-in-out infinite;
    }
  }
  .upbox_center {
    position: absolute;
    left: 25px;
    width: 530px;
    height: 90px;
    bottom: 57px;
    display: flex;
    justify-content: space-around;
    @keyframes glow {
      0%,
      100% {
        text-shadow: 1px 1px 30px red;
      }

      25% {
        text-shadow: 1px 1px 30px blue;
      }

      50% {
        text-shadow: 1px 1px 30px forestgreen;
      }

      75% {
        text-shadow: 1px 1px 30px cyan;
      }
    }
    .one,
    .two,
    .three {
      // position: absolute;
      width: 100px;
      height: 90px;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      line-height: 50px;
      background-image: url(~@/assets/images/background/green.png);
    }
  }
  .upbox_bottom {
    position: absolute;
    left: 25px;
    width: 530px;
    height: 20px;
    bottom: 27px;
    display: flex;
    // background-image: url(~@/assets/images/background/blue.png);
    justify-content: space-around;
    .one,
    .two,
    .three {
      width: 100px;
      height: 20px;
      font-size: 14px;
      text-align: center;
    }
  }
}
.centerbox {
  position: relative;
  width: 581px;
  height: 288px;
  top: -25px;
  background-image: url(~@/assets/images/background/RightCenter1.png);
  background-repeat: no-repeat;
  background-position: center;
  .centerbox_top {
    position: relative;
    width: 581px;
    height: 50px;
    .centerbox_top_title {
      position: absolute;
      width: 100px;
      height: 25px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 30px;
      left: 45px;
      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
      }
      .el-icon-arrow-down {
        font-size: 16px;
      }
    }
    .pull_up {
      position: relative;
      left: 127px;
      top: 10px;
      // z-index: 999;
      width: 20px;
      height: 60px;
      background-image: url(~@/assets/images/background/tab_right.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .pull_down {
      position: relative;
      left: 127px;
      top: 10px;
      // z-index: 999;
      width: 20px;
      height: 60px;
      background-image: url(~@/assets/images/background/tab_pull_down.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .centerbox_echarts {
    position: absolute;
    left: 25px;
    bottom: 10px;
    width: 265px;
    height: 210px;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    // border: #42b983 1px solid;
    .container {
      width: 265px;
      height: 210px;
    }
  }
  .centerbox_echarta {
    position: absolute;
    left: 280px;
    bottom: 10px;
    width: 265px;
    height: 210px;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    // border: #42b983 1px solid;
    .container {
      width: 265px;
      height: 210px;
    }
  }
}
.belowbox {
  width: 581px;
  height: 340px;
  background-image: url(~@/assets/images/background/RightBelow.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  .belowbox_top {
    position: relative;
    width: 680px;
    height: 50px;
    .belowbox_top_title {
      position: absolute;
      overflow: hidden;
      // width: 130px;
      // height: 200px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      top: 20px;
      left: 60px;
      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
      }
      .el-icon-arrow-down {
        font-size: 16px;
      }
    }
    .pull_up {
      position: relative;
      left: 125px;
      // z-index: 999;
      width: 20px;
      height: 60px;
      background-image: url(~@/assets/images/background/tab_right.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .pull_down {
      position: relative;
      left: 125px;
      // z-index: 999;
      width: 20px;
      height: 60px;
      background-image: url(~@/assets/images/background/tab_pull_down.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .curtainD {
    position: absolute;
    left: 15px;
    bottom: 50px;
    height: 60px;
    width: 550px;
    background-image: url(~@/assets/images/background/zu5.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .belowbox_op {
position: relative;
    left: 20px;
    width: 550px;
    height: 300px;
    overflow-y: hidden;
  .belowbox_bottom {
    position: relative;
    // left: 10px;
    width: 1000px;
    height: 300px;
  }
  }
  
}
</style>
